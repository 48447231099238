<template>
  <div class="mt-10">
    <div class="hayche-digital container">
      <div class="mb-5">
        <h1>GIS Assignments Undertaken by Hayche Digital</h1>
      </div>
      <div>
        <div
          class="mb-7"
          v-for="(assignment, index) in assignments"
          :key="index"
        >
          <p class="title mb-1">{{ assignment.title }}</p>
          <p class="client mb-1">{{ assignment.client }}</p>
          <p class="description">{{ assignment.description }}</p>
        </div>
      </div>
      <a class="cta-grid mb-5" href="https://blog.hayche.net/hayche-digital">
        <button>See all GIS Assignments</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assignments: [
        {
          id: 1,
          title: `Mapping Poverty in the Federal Republic of Nigeria`,
          client: `Federal Government of Nigeria, Millennium Development Goals (MDG)`,
          description: `The objective of the exercise was to carry out poverty mapping in 
          twelve select states within the six geo-political zones of Nigeria that will meet the 
          information needs of the key players and stakeholders; the specific objectives of the 
          mapping exercise were`,
        },
        {
          id: 2,
          title: `Mapping Water Schemes in Ethopia’s Somali Regional State`,
          client: `Somali Regional Bureau of Finance & Economic Development (BoFED)/USAID 
          “Regional/Municipal Services strenghthening (RMSS)” Project `,
          description: `A pilot project to establish a comprehensive regional water resources 
          inventory to include all relevant information on the hydrology, hydrogeology, and rainfall 
          characteristics of the target weredas as well as technical details of various water schemes 
          such as boreholes, shallow wells, birkas, and earth dams.`,
        },
        {
          id: 3,
          title: `Nasarawa State (Nigeria) SPEB Schools field guide`,
          client: `United States Agency for International Development (USAID)`,
          description: `To use GIS to show the location of all primary schools in Nasarawa State 
          (Nigeria) in relation to roads, towns, settlements, rivers, roads and LGA boundaries. 
          The resulting publication has served to verify the location of schools as well as LGA 
          boundaries and other supporting datasets.  And has also been used to stimulate discussion 
          around issues such as school clustering, access to education and improving education delivery`,
        },
        {
          id: 4,
          title: `Geo-Referenced Ward Level Maps for effective Immunization campaigns 
          (Pilot conducted in Kano State)`,
          client: `National Primary Health Care Development Agency (NPHCDA) / United States Agency for 
          International Development (USAID)`,
          description: `Nigeria has high child morbidity and mortality rates consistent with figures 
          for most developing countries (Eleweke, 1999). In 2004, the infant mortality rate (under 1 year) 
          was 101 deaths per 1000 live births, the under-5 mortality rate was 197 per 1000 live births, 
          and the fertility rate was 5.7 children born per woman (UNICEF, 2006).`,
        },
      ],
    };
  },
};
</script>

<style scoped>
.hayche-digital h1 {
  font-style: normal;
  font-size: 35px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .hayche-digital p {
  }
}

.title {
  font-family: Optima;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  letter-spacing: 0.01em;
}

.client {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.client::before {
  content: "Client : ";
  color: black;
  padding: 3px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  border-radius: 30px;
  background-color: rgba(140, 218, 181, 0.479);
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: black;
}

.cta-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 50px;
  width: 400px;
}

@media (max-width: 768px) {
  .cta-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 20px;
    width: 100%;
  }
}

.cta-grid button {
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: var(--button-border-radius);
  background-color: rgb(80, 80, 80);
  padding: 15px;
  cursor: pointer;
}
</style>