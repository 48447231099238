<template>
  <hero />
  <digitals/>
  <assignments/>
</template>

<script>
import hero from "./hero";
import digitals from "./digitals";
import assignments from "./assignments";

export default {
  components: {
    hero,
    digitals,
    assignments
  },
};
</script>

