<template>
  <!-- hero section -->
  <div class="hero">
    <section>
      <div class="mb-10">
      <div class="content">
      <div class="wrapper">
        <h1 class="sub-heading mb-2">Hayche Digital Solutions</h1>
        <div class="mb-4">
        <p class="description">
          The beneficiary enrollment, and management (BEaM) system, 
          an integrated platform for the efficient implementation of 
          universal health coverage (UHC) developed on behalf of the 
          Enugu State Agency for Universal Health Coverage (ESAUHC). 
          The BEAM is comprised of a web-based enrollment platform, the 
          BEAM database for managing beneficiary data, and an electronic 
          medical records (EMR) deployed to primary health centers (PHCs), 
          all connected to the BEAM cloud database. 
        </p>
      </div>
        <a class="cta-grid" href="https://blog.hayche.net/hayche-digital">
          <button>See GIS Assignments</button>
        </a>
      </div>
      </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
/* start of hero */
.hero {
  background: linear-gradient(50.23deg, #000000 1%, rgba(0, 0, 0, 0.59) 75.1%),
    url("https://res.cloudinary.com/hayche-network/image/upload/v1646928350/website/images/digital/hero_vrney5.png");
  width: 100%;
  height: 100vh;
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: grid;
  align-items: center;
}

@media (max-width: 768px) {
  .hero {
  }
}

.hero .content {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.hero .wrapper {
  display: grid;
  place-items: center;
}

.hero .content h1 {
  font-style: normal;
  color: white;
}

@media (max-width: 768px) {
}

.hero .content .heading {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
  margin-bottom: 22px;
}

.hero .content .description {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.5;
  color: white;
  text-align: center;
  width: 700px;
}

.hero .content img {
  width: 372px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .hero .content img {
    width: 100%;
  }

  .hero .content {
    width: 100%;
  }

  .hero .content h1 {
    font-size: 45px;
  }
}

.cta-grid {
  display: grid;
  column-gap: 20px;
  row-gap: 50px;
  width: 400px;
}

@media (max-width: 768px) {
  .hero .content .description {
    width: 100%;
  }

  .cta-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 20px;
    width: 100%;
  }
}

.cta-grid button {
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  color: #3a3a3c;
  border: none;
  border-radius: var(--button-border-radius);
  background-color: white;
  padding: 15px;
  cursor: pointer;
}
/* end of hero */
</style>