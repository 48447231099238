<template>
  <section>
    <div class="corevalue-grid">
      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928339/website/images/digital/data-management-solutions_a2m2zd.png"
          alt="Data Management Solutions"
        />
        <p>Data Management Solutions</p>
      </div>

      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928336/website/images/digital/mobile-data-solutions_ewdwkq.png"
          alt="Mobile Data Solutions"
        />
        <p>Mobile Data Solutions</p>
      </div>

      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928338/website/images/digital/geographical-information-systems_hqo0cg.png"
          alt="Geographical Information Systems"
        />
        <p>Geographical Information Systems</p>
      </div>
    </div>
  </section>
</template>


<style scoped>
.corevalue-grid {
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 27px;
  row-gap: 40px;
  width: 100%;
}

.corevalue-grid .grid-item {
  width: 90%;
}

@media (max-width: 768px) {
  .corevalue-grid .grid-item {
    width: 100%;
  }
}

.corevalue-grid .grid-item img {
  object-fit: cover;
  width: 100%;
  margin-bottom: 1em;
  border-radius: var(--border-radius) !important;
}

.corevalue-grid .grid-item p {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: #1f1f1f;
  font-family: "Optima";
}
</style>